export default {
  message: {
    // 登录页
    BIKE_RESERVATION: '车辆预定',
    LOGIN_TO_ANYWHEEL: '预订自行车，请先登录或注册 Anywheel 账户',
    LOGIN_ANYWHEEL_MOBILENO: '使用手机号登录',
    MOBILE_NO: '手机号',
    ENTER_VERIFICATION_CODE: '输入验证码',
    GET_VERIFICATION_CODE: '获取验证码',
    RESERVE_A_BIKE: '预定车辆',
    NOT_ANYWHEEL_REGISTER: '还未注册Anywheel app？',
    NOTE: '提示：',
    RESERVATION_NOTE1: '1.自行车仅保留%s分钟，请在%s分钟内使用自行车。',
    RESERVATION_NOTE2: '2. 如果你在%s分钟内不使用自行车，将会收取您S$1的预订费用。',
    RESERVATION_NOTE3: '3. 以下行程将收取预订费用：',
    TRIPS_CHARGE1: '-骑行时间少于1分钟',
    TRIPS_CHARGE2: '-5分钟内在同一停车区停车',
    RESERVATION_NOTE4: '4. 每天仅有1次取消预定的机会。',
    ENTER_MOBILE_NUMBER: '请先输入您的手机号',
    RESEND_VERIFICATION_CODE: '%ss后重新获取验证码',
    VERIFICATION_CODE_ERROR: '请输入正确的验证码',
    // CONTACT_US: 'Contact Us',
    RESEND_CODE: '重新获取验证码',
    // CONTACT_USS: 'Contact Us!',
    // DONT_WORRY: 'Don\'t worry, we\'re here to assist you',
    CONTACT_ANYWHEEL: '联系Anywheel请通过 WhatsApp: +6596283180',
    REGISTER_HERE: '立即注册！',
    FORMATTED_PHONE_NO: '手机号格式不正确',
    REGISTER_NOW: '立即注册！',
    GOT_IT: '知道了',
    RESERVATION_NOTE5: '5. 车辆预定成功后，您可以扫码任意一辆单车开始行程',
    // 成功页
    RESERVATION_SUCCESS: '预定成功',
    RESERVATION_MINUTES: '您的预订将在%s分钟内有效',
    USE_BIKE_NOW: '立即用车！',
    // 失败1
    RESERVATION_FAILED: '预定失败',
    // TOP_UP_ACCOUNT: '哎呀，看起来您的账户余额不足。请充值您的Anywheel账户后再预定我们的单车。',
    TOP_UP_ACCOUNT: '哎呀，看起来您的账户%s。请充值您的Anywheel账户后再预定我们的单车。',
    TOP_UP_INSUFFICIEN: '余额不足',
    TOP_UP_NOW: '立即充值！',
    // 失败2
    NO_BIKES_CHOSEN_AREA: '抱歉，当前区域无可预订的车辆',
    // 失败3
    REACHED_MAXIMUM: '哎呀，看来您今天的预定的次数已经达到了上限',
    // 失败4
    NOT_BOOK_AGAIN: '您已经有一个预定的订单了，无法再次预定',
    SYSTEM_REEORR: '系统错误'

  }
}
