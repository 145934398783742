export default {
  message: {
    // 登录页
    BIKE_RESERVATION: 'การจองจักรยาน',
    LOGIN_TO_ANYWHEEL: 'โปรดสร้างบัญชีหรือลงชื่อเข้าใช้กับเอนี่วีลก่อนการจองจักรยาน',
    LOGIN_ANYWHEEL_MOBILENO: 'ลงชื่อเข้าใช้ด้วยเบอร์โทรศัพท์',
    MOBILE_NO: 'หมายเลขโทรศัพท์',
    ENTER_VERIFICATION_CODE: 'ป้อนรหัสยืนยัน',
    GET_VERIFICATION_CODE: 'รับรหัสยืนยัน',
    RESERVE_A_BIKE: 'จองจักรยาน',
    NOT_ANYWHEEL_REGISTER: 'ไม่มีบัญชีเอนี่วีลหรือ?',
    NOTE: 'คำแนะนำ:',
    RESERVATION_NOTE1: '1. จักรยานสงวนสำหรับการจองไว้เพียง %s นาที โปรดใช้จักรยานภายใน %s นาที',
    RESERVATION_NOTE2: '2. หากคุณไม่ใช้จักรยานภายใน %s นาที คุณจะถูกเรียกเก็บค่าธรรมเนียมการจอง S$1',
    RESERVATION_NOTE3: '3. จะมีการเรียกเก็บค่าธรรมเนียมการจองสำหรับแผนการเดินทางต่อไปนี้:',
    TRIPS_CHARGE1: '-การเดินทางต่ำกว่า 1 นาที',
    TRIPS_CHARGE2: '-จบการเดินทางภายใน 5 นาทีในบริเวณที่จุดจอดเดียวกัน',
    RESERVATION_NOTE4: '4. คุณสามารถยกเลิกการจองได้เพียง 1 ครั้งต่อ 1 วัน',
    ENTER_MOBILE_NUMBER: 'โปรดป้อนเบอร์โทรศัพท์ของคุณ',
    RESEND_VERIFICATION_CODE: 'ใช้รหัสยืนยันภายใน%ss',
    VERIFICATION_CODE_ERROR: 'โปรดป้อนรหัสยืนยันตัวตน',
    // CONTACT_US: 'Contact Us',
    RESEND_CODE: 'รับรหัสยืนยันใหม่',
    // CONTACT_USS: 'Contact Us!',
    // DONT_WORRY: 'Don\'t worry, we\'re here to assist you',
    CONTACT_ANYWHEEL: 'ติดต่อเราผ่าน WhatsApp: +6596283180',
    REGISTER_HERE: 'ลงทะเบียนเลย!',
    FORMATTED_PHONE_NO: 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง',
    REGISTER_NOW: 'ลงทะเบียนตอนนี้!',
    GOT_IT: 'อ่านและยอมรับ',
    RESERVATION_NOTE5: '5. คุณสามารถสแกนใช้งานจักรยานคันใดก็ได้หลังจากทำการจองสำเร็จ',
    // 成功页
    RESERVATION_SUCCESS: 'การจองสำเร็จ',
    RESERVATION_MINUTES: 'ระยะเวลาการจองที่ใช้ได้ %s นาที',
    USE_BIKE_NOW: 'ปั่นจักรยานเลย!',
    // 失败1
    RESERVATION_FAILED: 'การจองล้มเหลว',
    TOP_UP_ACCOUNT: 'ไม่นะ ดูเหมือนว่ายอดเงินในบัญชีของคุณเหลือน้อย กรุณาเติมเงินในบัญชี Anywheel ของคุณก่อนจองจักรยานของเรา',
    TOP_UP_NOW: 'เติมเงินเลย!',
    TOP_UP_INSUFFICIEN: 'จำนวนเงินในแอปไม่เพียงพอ',
    // 失败2
    NO_BIKES_CHOSEN_AREA: 'ขออภัย ไม่มีจักรยานพร้อมใช้งานในบริเวณที่คุณเลือก',
    // 失败3
    REACHED_MAXIMUM: 'อุ๊ปส์ จำนวนการจองจักรยานต่อวันของคุณครบแล้ว',
    // 失败4
    NOT_BOOK_AGAIN: 'ขออภัย คุณจองจักรยานไว้อยู่แล้ว กรุณาจองอีกครั้งเมื่อจบการเดินทางดังกล่าว',
    SYSTEM_REEORR: 'ระบบล้มเหลว'

  }
}
