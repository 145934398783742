import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { Locale } from 'vant'
import enUS from 'vant/lib/locale/lang/en-US'
import zhCN from 'vant/lib/locale/lang/zh-CN'
import thTH from 'vant/lib/locale/lang/th-TH'
import thLocale from './th'

import enLocale from './en_us'
import zhLocale from './zh_cn'

Vue.use(VueI18n)

const messages = {
  en: {
    ...enUS,
    ...enLocale
  },
  zh: {
    ...zhCN,
    ...zhLocale
  },
  th: {
    ...thTH,
    ...thLocale
  }
}

// 更新vant组件库本身的语言变化，支持国际化
function vantLocales (lang) {
  if (lang === 'en') {
    Locale.use(lang, enUS)
  } else if (lang === 'zh') {
    Locale.use(lang, zhCN)
  } else if (lang === 'th') {
    Locale.use(lang, thTH)
  }
}
// 获取浏览器的语言
const lang = (navigator.language || 'en').toLocaleLowerCase()
// const language = localStorage.getItem('language') || lang.split('-')[0] || 'zh'
// localStorage.setItem('language', language)

const i18n = new VueI18n({
  // Not available in legacy mode 解决报错问题的配置项！！！
  legacy: false,
  // 全局注册 $t方法
  globalInjection: true,
  // 设置初始化语言
  locale: lang === 'zh-cn' ? 'zh' : lang,
  // 设置备用语言
  fallbackLocale: 'en',
  messages
})

export { i18n, vantLocales }
