import Vue from 'vue'
import VueRouter from 'vue-router'
import { i18n, vantLocales } from '@/lang'
vantLocales(i18n.locale)
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    redirect: '/reservationLogin',
    component: () => import('@/views/reservationLogin.vue'),
    meta: { title: 'message.BIKE_RESERVATION' }

  },
  {
    path: '/reservationLogin',
    name: 'ReservationLogin',
    component: () => import('@/views/reservationLogin.vue'),
    meta: { title: 'message.BIKE_RESERVATION' }
  },
  {
    path: '/resultSuccess',
    name: 'ResultSuccess',
    component: () => import('@/views/resultSuccess.vue'),
    meta: { title: 'message.BIKE_RESERVATION' }

  },
  {
    path: '/failedOne',
    name: 'FailedOne',
    component: () => import('@/views/failedOne.vue'),
    meta: { title: 'message.BIKE_RESERVATION' }

  },
  {
    path: '/failedTwo',
    name: 'FailedTwo',
    component: () => import('@/views/failedTwo.vue'),
    meta: { title: 'message.BIKE_RESERVATION' }

  },
  {
    path: '/failedThree',
    name: 'FailedThree',
    component: () => import('@/views/failedThree.vue'),
    meta: { title: 'message.BIKE_RESERVATION' }

  },
  {
    path: '/failedFour',
    name: 'FailedFour',
    component: () => import('@/views/failedFour.vue'),
    meta: { title: 'message.BIKE_RESERVATION' }
  },
  // sg bike
  {
    path: '/sgbike',
    name: 'sgbike',
    component: () => import('@/views/sgbike.vue'),
    meta: { title: 'Anywheel & SG Bike' }
  },
  {
    path: '/sgbikeWeb',
    name: 'sgbikeWeb',
    component: () => import('@/views/sgbikeWeb.vue'),
    meta: { title: 'Anywheel & SG Bike' }
  }
]
const router = new VueRouter({
  mode: 'history',
  routes
})
// 统一配标题
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = i18n.t(to.meta.title)
    console.log(i18n, 'Vue.i18n')
    console.log('document.title', document.title)
  }
  next()
})

export default router
