<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="less">
// 更改所有toast的位置为固定高度中间位置
.noticeWidth{
  position:absolute !important;
  top:333.5px !important;
  left:50% !important;

}

</style>
