import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/index.less'
import Vant from 'vant'
import 'vant/lib/index.css'
// 引入控制台
// import VConsole from 'vconsole'
// 人机校验
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
// 国际化

import { i18n, vantLocales } from './lang'
import '@/assets/font/font.css'
// import 'element-ui/lib/theme-chalk/index.css'
// 动态设置REM基准值
import 'amfe-flexible'
// 初始化 VConsole
// Vue.prototype.$vconsole = new VConsole()

vantLocales(i18n.locale)

Vue.use(Vant) // lang i18n
Vue.use(Antd)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
