export default {
  message: {
    // 登录页
    BIKE_RESERVATION: 'Bicycle Reservation',
    LOGIN_TO_ANYWHEEL: 'To reserve a bicycle, please log in or register for an Anywheel account',
    LOGIN_ANYWHEEL_MOBILENO: 'Log in by mobile number',
    MOBILE_NO: 'Mobile No.',
    ENTER_VERIFICATION_CODE: 'Enter Verification Code',
    GET_VERIFICATION_CODE: 'Get Verification Code',
    RESERVE_A_BIKE: 'Reserve a Bicycle',
    NOT_ANYWHEEL_REGISTER: 'Do not have an Anywheel account?',
    NOTE: 'NOTE:',
    RESERVATION_NOTE1: '1. The bicycle will only be reserved for %s minutes. Please use the bicycle within %s minutes.',
    RESERVATION_NOTE2: '2. If you do not use the bicycle within %s minutes, you will be charge a reservation fee of S$1.',
    RESERVATION_NOTE3: '3. The following trips will incur a reservation fee:',
    TRIPS_CHARGE1: '-Trip is less than 1 minute',
    TRIPS_CHARGE2: '-Ending the trip at the same parking area that the bicycle was hired within 5 minutes',
    RESERVATION_NOTE4: '4. You may only cancel 1 reservation per calendar day.',
    ENTER_MOBILE_NUMBER: 'Please enter your mobile number',
    RESEND_VERIFICATION_CODE: '%ss Resend Verification Code',
    VERIFICATION_CODE_ERROR: 'VERIFICATION_CODE_ERROR',
    CONTACT_US: 'Contact Us',
    RESEND_CODE: 'Resend Verification Code',
    CONTACT_USS: 'Contact Us!',
    DONT_WORRY: 'Don\'t worry, we\'re here to assist you',
    CONTACT_ANYWHEEL: 'Contact Anywheel via WhatsApp: +6596283180',
    REGISTER_HERE: 'Register here!',
    FORMATTED_PHONE_NO: 'Incorrectly formatted cell phone no.',
    REGISTER_NOW: 'Register Now!',
    GOT_IT: 'Got it',
    RESERVATION_NOTE5: '5. You may scan any bicycle to start your trip after you have reserved a bicycle',
    // 成功页
    RESERVATION_SUCCESS: 'Reservation Success',
    RESERVATION_MINUTES: 'Your reservation will be valid for %s minutes',
    USE_BIKE_NOW: 'Use Bicycle Now!',
    // 失败1
    RESERVATION_FAILED: 'Reservation Failed',
    TOP_UP_ACCOUNT: 'Oh no, seems like you have %s. Please top up your Anywheel account to reserve a bicycle with us',
    TOP_UP_INSUFFICIEN: 'insufficient credit balance',
    TOP_UP_NOW: 'Top Up Now!',
    // 失败2
    NO_BIKES_CHOSEN_AREA: 'Sorry! No available bicycles at the chosen parking station',
    // 失败3
    REACHED_MAXIMUM: 'Oops, seems like you have exceeded the number of reservations for today',
    // 失败4
    NOT_BOOK_AGAIN: 'Sorry, you have an existing reservation. Please try again after your ride',
    SYSTEM_REEORR: 'System error'

  }
}
